<template>
  <div>
    <!-- header -->
    <CurvedHeader
      title="FAVOURITES"
      icon="heart" />

    <!-- reminder card -->
    <div class="reminder-card pa-4">
      <div class="d-flex justify-space-between align-center mb-4">
        <v-btn
          elevation="0"
          style="background-color: transparent"
          class="text-capitalize"
          @click="goBackToProfile">
          <v-icon
            class="mr-2"
            color="primaryColor"
            >mdi-arrow-left</v-icon
          >
          Back to profile
        </v-btn>

        <v-btn
          @click="openCourseFiltersDialog"
          icon>
          <v-img :src="require('@/assets/course/filtersIcon.png')"></v-img>
        </v-btn>
      </div>

      <!-- favourite-courses -->
      <v-row no-gutters>
        <v-col
          cols="6"
          class="pa-1"
          v-for="course in filteredCourses"
          :key="course.id">
          <CourseGridCard :course="course" />
        </v-col>
        <div
          style="margin-left: 30%"
          class="mt-14 text-center"
          v-if="!favoriteCourses.length">
          No favorite found...
        </div>
      </v-row>
    </div>

    <v-bottom-sheet
      v-model="showCourseFiltersDialog"
      bottom>
      <transition
        name="fade"
        mode="out-in"
        appear>
        <course-filters-dialog
          :mediaType="mediaType"
          :maxTime="maxTime"
          @closeDialog="closeCourseFiltersDialog"
          @setMediaType="setMediaType"
          @setMaxTime="setMaxTime"
          @filterCourses="filterCourses(favoriteCourses)"
          @clearAllFilters="resetCourses(favoriteCourses)" />
      </transition>
    </v-bottom-sheet>
  </div>
</template>

<script>
  import FavouritesMixin from '@/views/profile/favourites/Favourites.mixin.vue';
  export default {
    mixins: [FavouritesMixin],
  };
</script>
